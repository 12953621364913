h1,h2,h3,h4,h5,h6{
  font-family: 'Source Sans Pro', sans-serif!important;
  font-style:italic;
  font-weight:700;
}


// topbar
.topbar{
  background:url(../img/bg.png) left top repeat;
  height:29px;
  width:100%;
  color:#fff;
  font-size:12px;
  line-height:29px;

  // links
  .topbar-link{
    a,span{
      font-style: italic;
      color:#fff;
      margin-right:30px;
      transition:text-shadow .4s ease;
      &:last-child{
        margin-right:0;
      }
      i.fa{
        margin-right:6px;
      }
      &:hover{
        text-shadow:1px 1px 2px rgba(0,0,0,.5);
      }
    }
    @media (max-width:$screen-xs-max){
      text-align:center;
    }
  }

  // social
  .topbar-social{
    //@include clearfix();
    text-align:right;
    transition:all .4s ease;
    a{
      display:inline-block;
      height:29px;
      width:29px;
      text-align:center;
      color:#fff;
      font-size:12px;
      line-height:29px;
      margin-right:10px;
      //float:left;
      &:last-child{
        margin-right:0;
      }
      &:hover{
        background:rgba(255,255,255,.2);
        text-shadow:1px 1px 2px rgba(0,0,0,.5);
      }
    }
  }
}

// header
header.header{
  padding:40px 0;

  // logo
  .logo{
    img{
    vertical-align:middle;
    max-width:100%;
    }
    @media (max-width:$screen-xs-max){
      text-align:center;
    }
  }

  // nav
  .main-menu{
    float:left;
    font-size:14px;
    line-height:18px;
    font-weight:600;
    margin:0;
    padding:8px 0;
    list-style: none;
    @include clearfix();
    li{
      display:inline-block;
      float:left;
      a,span{
        @include skewx();

        color:#1d3056;
        display:block;
        padding:3px 12px;
        border-right:1px solid #eb5b26;
        &:hover{
          color:#eb5b26;
        }

        i{
          margin-right:6px;
        }
      }

      &.hover{
        a,span {
          color:#eb5b26;
        }
      }
      &:last-child{
        a,span{
          border-right:none;
        }
      }
    }
    //&.active{
    @media (max-width:$screen-sm-max){
      width:100%;
      margin:0;
      overflow:hidden;
      left:0;
      background:#fff;
      li{
        display:block;
        float:none;
        a,span{
          padding:12px 20px;
          border-right:none;
          border-top:1px solid rgba(235, 91, 38, 0.11);
          &:hover{
            background:#f8f8f8;
          }
        }
      }
    }
  }

  // search
  .search{
    @include clearfix;
    float:right;
    max-width:222px;
    padding:5px 0 5px 20px;
    /*
    input{
      height:27px;
      display:inline-block;
      float:left;
      line-height:21px;
      font-size:12px;
      width:180px;
      color:#aaa;
      border:1px solid #d9d9d9;
      padding:2px 12px 2px 6px;
      z-index: 1;
      font-style: italic;
      transition:all .3s ease;
      &:focus,
      &:active,
      &:hover{
        color: #555;
      }
      &:focus{
        border-color:#eb5b26;
      }
    }
    button{
      @include skewx();
      transition:all .3s ease;
      display:inline-block;
      float:left;
      font-size:14px;
      padding:0;
      height:27px;

      background: #eb5b26;
      border: none;
      color: #fff;
      width: 32px;
      line-height: 22px;
      margin-left: -5px;
      text-align: center;
      z-index: 2;
      i.fa{
        vertical-align: baseline;
      }
      &:focus,
      &:active,
      &:hover{
        background: #cd5a26;
      }
    }
    */
  }

  // medium
  @media (min-width:$screen-md-min) and (max-width:$screen-md-max){
      .main-menu{
        float:right;
        padding:0;
        margin-top:-13px;
        margin-bottom:13px;
      }
      .search{
        float:right;
        padding:0;
      }
  }

  // small
  @media (min-width:$screen-sm-min) and (max-width:$screen-sm-max){
      .main-menu{
        float:none;
        padding:0;
      }
      .search{
        float:right;
        padding: 5px;
      }
  }
  // xsmall
  @media (max-width:$screen-xs-max){
      .main-menu{
        float:none;
        padding:0;
      }
      .search{
        float:left;
        padding: 5px;
      }
      .responsive-menu{
        max-width: 270px;
        margin:15px auto auto;
      }
  }
}