.form-kzp{
  input:not(.default),textarea{
    font-size: 14px;
    line-height: 1.42857;
    color: #1d3056;
    display: block;
    width: 100%;
    padding: 6px 12px;
    background-color: #fff;
    border: 1px solid #1d3056;
    border-radius: 0;
    //box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    &:hover{
      border-color:#eb5b26;
    }
    &:active,
    &:focus{
      border-color:#eb5b26;
      color:#eb5b26;
    }
    &.error{
      color:#ff0000;
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="number"]
  {
    @include skewx();
    height: 34px;
  }
  textarea{
    resize: vertical;
  }

  .kzp-group{
    cursor:pointer;
    &:hover{
      input,
      >span{
        border-color:#eb5b26;
        color:#eb5b26;
        background:#fff;
        cursor:pointer!important;
      }
      .kzp-group-addon{
        border-color:#eb5b26;
        color:#fff;
        background:#eb5b26;
      }
    }
    input:focus + .kzp-group-addon,
    input:active + .kzp-group-addon,
    .kzp-group-addon:focus + input,
    .kzp-group-addon:active + input{
      border-color:#eb5b26;
      color:#fff;
      background:#eb5b26;
    }
    &:focus,
    &:active{
      input,
      >span{
        border-color:#eb5b26;
        color:#eb5b26;
        background:#fff;
        cursor:pointer!important;
      }
      .kzp-group-addon{
        border-color:#eb5b26;
        color:#fff;
        background:#eb5b26;
      }
    }
  }

  .kzp-group-addon{
    @include skewx();
    position: relative;
    z-index: 2;
    //box-shadow: -2px 0 0px -2px #1d3056;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    text-align: center;
    background-color: #1d3056;
    border: 1px solid #1d3056;
    border-radius: 0;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
    cursor:pointer;
    &:hover,
    &:focus,
    &:active{
      background-color:#eb5b26;
      border-color:#eb5b26;
    }
  }

  .kzp-group-addon-text{
    font-style: italic;
    position: relative;
    z-index: 2;
    //box-shadow: -2px 0 0px -2px #1d3056;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #1d3056;
    text-align: center;
    font-weight: 700;
    border-radius: 0;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
  }

}

.radio-inline,
.checkbox-inline{
  padding-left: 0px;
  .checkbox, .radio {
    display:inline-block;
    margin-left:0px;
    + .checkbox, + .radio {
      margin-left:20px;
    }
  }
}
.checkbox, .radio {
  margin-bottom: 10px;
  //line-height:28px;
  padding-left:0px;
  position:relative;
  label{
    padding:5px 0 5px 40px!important;
  }
}

/* --- START --- */

.checkbox *,
.radio *{
  cursor: pointer;
}

.checkbox input,
.radio input {
  opacity: 0;
  display:none;
}

.checkbox span,
.radio span{
  @include skewx();
  position: absolute;
  left:0;
  top:0;
  display: inline-block;
  //margin-left: -25px;
  //margin-right: 10px;
  vertical-align: top;
  width: 30px;
  height: 30px;
  border: 3px solid #1d305b;
}

.checkbox:hover span,
.radio:hover span{
  border-color: #1d305b;
}

.checkbox span:before,
.radio span:before{
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  content: "\f00c";
  position: absolute;
  top: 0px; left: 5px;
  opacity: 0;
  text-align: center;
  //font-size: 16px;
  line-height: 16px;
  vertical-align: middle;
  color: #1d305b;
}

/* override for radio */
.radio span {
  //border-radius: 50%;
}
.checkbox span:before,
.radio span:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 5px auto;


  text-rendering: auto;


  content: "\f00c";
  color:#1d305b;
  //background-color: teal;
  //border-radius: 100px;
}

.checkbox input[type=checkbox]:checked + span,
.radio input[type=radio]:checked + span{
  border-color: #1d305b;
  //background-color: #1d305b;
}

.radio input[type=radio]:checked + span{
  background-color: #fff;
}

.checkbox input[type=checkbox]:checked + span:before,
.radio input[type=radio]:checked + span:before{
  color: #1d305b;
  opacity: 1;
  transition: color .3 ease-out;
}

.checkbox input[type=checkbox]:disabled + span,
.radio input[type=radio]:disabled + span{
  border-color: #ddd !important;
  background-color: #ddd !important;
}


.input-mask{

}



// dropdown

.dropdown{
  //@include skewx();
  background:#eee;
  //display:inline-block;
  //margin:100px;
  cursor:pointer;
 // border-radius:8px;
  z-index: 20;
  span{
    //padding:10px;
    //background:#bdc3c7;
    //display:inline-block;
    //position:relative;
    //padding-right:40px;
    @include skewx();
    height: 34px;
    font-size: 14px;
    line-height: 1.42857;
    color: #aaa;
    display: block;
    width: 100%;
    padding: 6px 12px;
    background-color: #fff;
    border: 1px solid #1d3056;
    border-radius: 0;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }
  &.dropdown-click span{
    color: #1d3056;
    &:hover{
      border-color: #eb5b26;
    }
  }
}
.dropdown span:after{
  display:inline;
  width:20px;
  height:20px;
  content:'';
  position:absolute;
  top:10px;
  right:0;
  margin-right:10px;
}
.dropdown ul{
  display:none;
}
.dropdown li{
  display:block;
  padding:10px;
  list-style:none;
}
.dropdown li:hover{
  background:#2980b9;
  color:#fff;
}


label.error{
  position: absolute;
  right: 0;
  bottom: -20px;
  color: #ff0000;
  font-weight: 400;
  font-size: 11px;
}