footer{
  //padding-top: 30px;
  .footer-copy{
    padding:25px 0;
    background:#1d3056;
    border-top:2px solid #454648;
    font-style: italic;
    color: #fff;
    .container{
      .row{
        > div:first-child{
          text-align:left;
        }
        > div:last-child{
          text-align:right;
        }
        @media(max-width:$screen-xs-max){
          >div:first-child,
          >div:last-child{
            text-align:center;
            margin-bottom:10px;
          }
        }
      }
    }
    a{
      color: #ffffff;
      font-weight:600;
      &:hover{
        color: #eb5b26;
      }
    }
  }
  @media (max-width:$screen-xs-max) {
    padding-top:15px;
  }
}
.google-maps{
  @include clearfix();
  @media(max-width:$screen-xs-max){
    display:none!important;
  }
  .maps{
    width:60%;
    float:left;
  }
  .maps-label{
    @include clearfix();
    width:40%;
    float:left;

      background:#fff;
      position:relative;
      top:0;
      margin:0 auto;
      height:265px;
    padding-left: 60px;
    padding-top: 40px;
    color:#1d3056;
    font-size:18px;

    &:before {
      @include skewx();
      height: 265px;
      width: 70px;
      content: "";
      display: block;
      position: absolute;
      background: #fff;
      left: -30px;
      top: 0;
      box-shadow: -5px 1px 4px -1px rgba(0,0,0,.5);
    }
    .fix{
      float:left;
      text-align:right;
      position: relative;
      @media(max-width:$screen-md-max){
        margin-left: -40px;
      }
      @media(max-width:$screen-sm-max){
        margin-left: -70px;
        font-size:14px;
      }
    }
    .logo{
      margin-bottom:$grid-gutter-width+10;
    }
    .adress{
      font-style:italic;
    }
  }
}
#footer-google-maps {
    min-height:265px;
}
