/* 1. Ensure this sits above everything when visible
.modal {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
}
*/
.modal.is-visible {
  visibility: visible;
  display:block;
  overflow:auto;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background: hsla(0, 0%, 0%, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  top: 5em;
  left: 50%;
  width: 50em;
  margin-left: -25em;
  background-color: #fff;
  box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
  margin-bottom:6em;
  @media (max-width:$screen-xs-max){
    width:auto;
    margin-left:auto;
    left:0;
  }
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}

.modal-wrapper{
  padding:20px 20px 5px;

  .modal-header{
    border-color:#1d305b;
    padding: 0px 0px 5px 0px;
    h4{
      font-weight: 600;
      text-transform: uppercase;
      font-style: italic;
    }
  }

  .modal-body{
    padding: 10px 0px 10px 0px;
  }

  .modal-footer{
    padding: 10px 0px 0px 0px;
  }
}


/*
.modal-header,
.modal-content {
  padding: 1em;
}
.modal-header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.06);
  border-bottom: 1px solid #e8e8e8;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  color: #aaa;
  background: none;
  border: 0;
}

.modal-close:hover {
  color: #777;
}

.modal-heading {
  font-size: 1.125em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-content > *:first-child {
  margin-top: 0;
}

.modal-content > *:last-child {
  margin-bottom: 0;
}

*/

