

// box -
.box{
  margin-bottom:$grid-gutter-width;
  .box-title{
    font-size:20px;
    font-style:italic;
    font-weight:700;
    color:#1d3056;
    border-bottom:1px solid #1d3056;
    margin:0;
    padding:8px 0;
    margin-bottom:10px;
    text-transform:uppercase;
  }

  .box-content{
    color:#1d3056;
    font-style:italic;
    font-size:14px;
    line-height:18px;
    padding:8px 0;
    a{
      color:#eb5b26;
      &:hover{
        color: #cd5826;
      }
    }

    &.box-content-bg{
      background-position:right top;
      background-size:auto auto;
      background-repeat:no-repeat;
      padding-right:230px;
      @media (max-width:$screen-sm-max){
        padding-bottom: 230px!important;
        background-position: right bottom!important;
        padding-right:0;
      }
    }
  }


  ul{
    padding-left: 20px;
    li{
      margin-bottom: 12px;
    }
  }
}

// box -item
.box-item{
  margin-bottom:$grid-gutter-width;
  .item-title{
    font-size:18px;
    line-height:18px;
    font-style:italic;
    margin-bottom:$grid-gutter-width/2;
  }
  .item-content{
    padding-left:70px;
    position:relative;
    @include clearfix();
    .item-img{
      position:absolute;
      left:0;
      top:0;
      width:55px;
      margin-right:15px;
      text-align: center;
      img{
        vertical-align:middle;
        max-width:100%;
      }
    }
    .item-text{
      font-style:italic;
      font-size:14px;
      line-height:16px;
      min-height: 45px;
    }
  }
}


// box -img
.box-img{
  margin-bottom:$grid-gutter-width;
  .item-img-overflow{
    height:133px;
    width:100%;
    overflow:hidden;
    position:relative;
    margin-bottom:$grid-gutter-width/2;
    color:#5d5d5d;
    img{
      vertical-align:middle;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      min-width:100%;
      position:absolute;
      z-index:5;
      left:0;
      top:0;
    }
    .item-img-label{
      @include skewx();
      background:#eb5b26;
      font-size:12px;
      color:#fff;
      position:absolute;
      right:12px;
      top:0;
      padding:5px 6px;
      line-height:12px;
      z-index:10;
    }
  }
  .item-title{
    font-size:16px;
    font-style:italic;
  }
  .item-content{
    margin-top:$grid-gutter-width/2;
    font-size:14px;
    font-style:italic;
  }
}

// box -article
.box-aside-article{
  .item-article{
    @include clearfix;
    position:relative;
    padding-left:130px;
    min-height:100px;
    margin-bottom: $grid-gutter-width/2;
    .item-img{
      //float:left;
      position:absolute;
      left:0;
      top:0;
      width:100px;
      height:100px;
      overflow:hidden;
      img{
        vertical-align:middle;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        min-width:100%;
      }
    }
    .item-content{
      font-style:italic;
      .item-title{
        font-weight:600;
        font-size:16px;
        margin-bottom:10px;
        position:relative;
        &:before{
          @include skewx;

          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;

          color:#eb5b26;
          content:'\f105';
          position:absolute;
          left:-12px;
        }
      }
      .item-text{
        font-size:14px;
      }
    }
    @media (max-width:$screen-md-max){
      padding-left:0;
      .item-img {
        //float:left;
        position: static;
        left: 0;
        top: 0;
        width: 100%;
        height: 100px;
        overflow: hidden;
        margin-bottom:$grid-gutter-width/2;
      }
      .item-content{
        padding-left:12px;
      }

    }
  }
}


// global
.item-title{
  color:#1d3056!important;
  &:before{
    color:#1d3056!important;
  }
  &:hover{
    color:#eb5b26!important;
    &:before{
      color:#eb5b26!important;
    }
  }
  a{
    color:#1d3056!important;
    &:hover{
      color:#eb5b26!important;
    }
  }
}
.img-currency {
  height: 16px;
  margin: 0 6px;
  vertical-align: middle;
}




.grid {
  display: flex;
  flex-wrap: wrap;
  margin:0 -15px;
}
.grid-unit{
  flex-basis: 50%;
  width: 50%;
  padding: 0 15px;
}

@media screen and (max-width: $screen-sm-max) /*and (max-width: 999px)*/{

  .grid-unit{
    flex-basis: 100%;
    width: 100%;
    padding: 0 15px;
  }

}