.bold{
  font-weight:600;
}

.box-text-label{
  color:#1d3056;
  font-size:14px;
  font-weight:600;
  font-style:italic;
}

blockquote {
  background: #dfdfdf;
  line-height: 25px;
}
.alignleft {
  float: left;
  margin: 5px 10px 5px 0;
}
.alignright  {
  float: right;
  margin: 5px 0 5px 10px ;
}
.aligncenter {
  margin: 5px auto;
  display: block;
}
.alignnone {
  display:block;
  margin:5px 0;
}

