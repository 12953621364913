
.btn-kzp{
  @include skewx();
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background:#7c7c7c;
  text-transform:uppercase;
  transition:all .3s ease;

  padding:0;
  border:0;
  i.fa{
    background:#1d3056;
    width:25px;
    color:#fff;
    padding: 7px 0px;
    text-align:center;
    line-height: 1.42857;
    display:inline-block;
    vertical-align: middle;
    height:100%;
  }
  span{
    display:inline-block;
    color:#fff;
    line-height: 1.42857;
    padding: 6px 12px;
    vertical-align: middle;
    min-width: 90px;
  }

  &:hover{
    background:#eb5b26;
  }
  &:disabled{
    opacity:.75;
  }
}