*:focus,
*:active,
*:hover{
  outline:none;
}

hr{
  border-color:#1d3056!important;
}
.block{
  display:block;
}
.bodyOverflow{
  overflow:hidden;
}

.bg-gradient{
  background:url('../img/bg2.png') left top repeat-x #e9e9e9;
}
.bg-gray{
  background:#e9e9e9;
}

.margin-none{
  margin:0!important;
}

.img-fullwidth{
  width:100%;
}


.margin-bottom{
  margin-bottom:$grid-gutter-width/2;
}


.column-2{
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
  &.column-disabled-mobile{
    @media (max-width:$screen-xs-max){
      column-count:1;
    }
  }
}
.column-3{
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
  &.column-disabled-mobile{
    @media (max-width:$screen-xs-max){
      column-count:1;
    }
  }
}
.column-4{
  -webkit-column-count: 4; /* Chrome, Safari, Opera */
  -moz-column-count: 4; /* Firefox */
  column-count: 4;
  &.column-disabled-mobile{
    @media (max-width:$screen-xs-max){
      column-count:1;
    }
  }
}


/****************************************
scrollDown
*****************************************/
.scrollDown {
  @include skewx();
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index:10;
  box-shadow: 0 0 3px 1px #fff;
  //background-color: #bfbfbf;
  //padding: 5px 10px 5px;
  //font-size: 22px;
  color: #fff;
  //opacity: 0;
  //transition: all .4s ease-in-out 0s;
  //line-height: 1;
  //cursor:pointer;
  /*&:hover{
    background-color: #eb5b26;
  }*/

  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background:#7c7c7c;
  text-transform:uppercase;
  transition:all .3s ease;

  padding:0;
  border:0;

  i.fa{
    background:#eb5b26;
    width:25px;
    color:#fff;
    padding: 7px 0px;
    text-align:center;
    line-height: 1.42857;
    display:inline-block;
    vertical-align: middle;
    height:100%;
  }
  span{
    display:inline-block;
    color:#fff;
    line-height: 1.42857;
    padding: 6px 12px;
    vertical-align: middle;
    min-width: 90px;
  }

  &:hover{
    background:#1d3056;
  }
  &:disabled{
    opacity:.75;
  }
}

.img-inline-right{
  display:inline;
  float:right;
}

.img-inline-left{
  display:inline;
  float:left;
}

// normal
.padding-left{
  padding-left:$grid-gutter-width/2;
}
.padding-right{
  padding-right:$grid-gutter-width/2;
}
.padding-top{
  padding-top:$grid-gutter-width/2;
}
.padding-bottom{
  padding-bottom:$grid-gutter-width/2;
}

// small
.padding-left-small{
  padding-left:$grid-gutter-width/4;
}
.padding-right-small{
  padding-right:$grid-gutter-width/4;
}
.padding-top-small{
  padding-top:$grid-gutter-width/4;
}
.padding-bottom-small{
  padding-bottom:$grid-gutter-width/4;
}

// normal
.margin-left{
  margin-left:$grid-gutter-width/2;
}
.margin-right{
  margin-right:$grid-gutter-width/2;
}
.margin-top{
  margin-top:$grid-gutter-width/2;
}
.margin-bottom{
  margin-bottom:$grid-gutter-width/2;
}

// small
.margin-left-small{
  margin-left:$grid-gutter-width/4;
}
.margin-right-small{
  margin-right:$grid-gutter-width/4;
}
.margin-top-small{
  margin-top:$grid-gutter-width/4;
}
.margin-bottom-small{
  margin-bottom:$grid-gutter-width/4;
}

.img-baseline{
  img{
    vertical-align: baseline;
    margin:6px;
  }
}


#contact-google-maps{
  height:200px;
  margin-bottom:$grid-gutter-width;
  overflow:hidden;
}

.text-gray{
  color:#888;
}



.relative{
  position:relative;
}

.nav-kzp{
  position: absolute;
  z-index: 20;
  background: #1d3056;
  left: -3px;
  color: #fafafa;
  top: 34px;
  width:100%;
  li {
    display: block;
    padding: 10px;
    list-style: none;
    border-bottom: 1px solid #2e4573;
    &:hover{
      background: #eb5b26;
      color: #fff;
    }
  }
}



// googlr custom search engine
/* Google Search box styles */
#___gcse_0 {
  display: inline-block !important;
}

#___gcse_0 table {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: none !important;
  background: transparent !important;
  margin: 0 0 0 0 !important;
}

#___gcse_0 tbody {
  border-top: none !important;
}

#___gcse_0 .forum-table td,
#___gcse_0 td,
#___gcse_0 tr {
  padding: 0 !important;
  border-top: none !important;
  border-right: none !important;
  border: none !important;
}

#___gcse_0 input[type="text"] {
  padding: 4px !important;
  -webkit-border-radius: none;
  -moz-border-radius: none;
  border-radius: none;
  max-width: 999px;
  background: transparent !important;
  border: none !important;
}

.gsc-completion-container {
  border: 1px solid #ddd !important;
  background: #fff !important;
  font-size:14px;
  font-family: inherit !important;
  padding: 4px !important;
  color: #D14F2D !important;
  box-shadow:0 4px 4px -1px rgba(0,0,0,.4);
  td{
    padding:4px 10px!important;
    border-top: none !important;
    border-right: none !important;
    border: none !important;
  }
}

.gsc-completion-selected {
  margin: 0 -4px -4px -4px;
}

.gsc-completion-container b {
  color: #242424 !important;
  font-weight: 400;
}

.gsc-completion-container table {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: none !important;
}


.gsc-modal-background-image {
  background-color: #000 !important;
}

.gsc-modal-background-image-visible {
  opacity: 0.4 !important;
  -ms-filter: "alpha(opacity=40)" !important;
  filter: alpha(opacity=40) !important;
}

.gsc-results-wrapper-overlay {
  background: #F0F1ED !important;
  border: solid 1px #A9ADA4 !important;
}

.gsc-webResult.gsc-result,
.gsc-results .gsc-imageResult,
.gsc-webResult.gsc-result:hover,
.gsc-imageResult:hover {
  border-color: transparent !important;
  background-color: transparent !important;
}

.gsc-webResult.gsc-result {
  border: 1px solid transparent !important;
}

.gsc-webResult .gsc-result {
  border-bottom: 1px solid transparent !important;
}

#___gcse_0 .cse .gsc-control-cse,
#___gcse_0 .gsc-control-cse {
  padding: 0 !important;
  width: auto !important;
  display: inline-block;
}

form.gsc-search-box {
  margin-bottom: 0 !important;
}

#___gcse_0 .cse .gsc-control-cse,
#___gcse_0 .gsc-control-cse {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

#___gcse_0 .gsc-search-box .gsc-input>input:hover,
#___gcse_0 .gsc-input-box-hover {
  border: none !important;
  border-top-color: rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#___gcse_0 .gsc-input-box {
  background: #fff !important;
  border: solid 1px #d9d9d9 !important;
  height: 27px!important;
  box-shadow:none!important;
}

#___gcse_0 input[type=text]{
  line-height: 21px;
  font-size: 12px;
  color: #777;
}

input.gsc-search-button {
  background-image: none !important;
  @include skewx();
  transition:all .3s ease!important;
  display:inline-block!important;
  float:left!important;
  font-size:14px!important;
  padding:6px 8px!important;
  height:27px!important;

  background: #eb5b26!important;
  border: none!important;
  color: #fff!important;
  width: 32px!important;
  line-height: 22px!important;
  margin-left: -5px!important;
  text-align: center!important;
  z-index: 2!important;
  margin-top:0!important;
  /*
  i.fa{
    vertical-align: baseline;
  }
  */
  &:focus,
  &:active,
  &:hover{
    background: #cd5a26!important;
  }

}

.map-link{
  background: #fff;
  color: #333;
  font-size: 14px;
  position: absolute;
  z-index: 11;
  left: 50%;
  top: 50%;
  padding: 8px 15px;
  border: 1px solid rgba(235, 91, 38, 0.37);
  margin-top: -18px;
  margin-left: -70px;
  display: block;
  box-shadow: 0 0 7px 0 rgba(55,55,55,.3);
  &:hover{
    color:#111;
    border-color:rgba(235, 91, 38, 1);
  }
}