
ol.label-step{
  @include clearfix;
  margin:$grid-gutter-width auto $grid-gutter-width auto;
  padding:0;
  list-style:none;
  text-align: center;
  display: inline-block;
  li{
    display:inline-block;
    float:left;
    > *{
      @include skewx();
      display:inline-block;
      padding: 10px 12px;
      min-width:170px;
      line-height: 1.42857;
      white-space: nowrap;
      font-size: 14px;
      color:#fff;
      background:#7c7c7c;
      font-weight: 400;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      text-transform:uppercase;
      transition:all .3s ease;
      border:1px solid #eb5b26;
      cursor:pointer;
    }

    &:hover,
    &.active{
      > *{
        background:#eb5b26;
      }
    }
    &.active{
      > *{
        cursor:default;
      }
    }

  }
}

ul.box-list{
  list-style:none;
  margin:0 0 $grid-gutter-width/2;
  > li {
    position:relative;
    &:before{
      position:absolute;
      left: -12px;
      top:0;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 16px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;

      color:#eb5b26;
      content:'\f105';
    }
    > ul {
      margin-top:10px;
    }
  }
}