$responsive-menu-breakpoint: $screen-sm-max;

@mixin hide-menu {
  opacity: 0;
  pointer-events: none;
  margin-top: 20px;
  transition: 200ms all;

  position: absolute;
  top: 100%;
  left: 0;

  right: auto;

  min-width: 200px;
}

@mixin show-menu {
  opacity: 1;
  pointer-events: auto;
  margin-top: 0;

  z-index: 20;
}

.responsive-menu {
  //position: relative;
  .toggle-menu{
    margin-left:8px;
    svg{
      @include skewx();
      vertical-align:middle;
      path{
        color:#1d3056;
        fill:#1d3056;
      }
    }
    &:hover,
    &:focus{
      svg{
        path{
          color:#cd5a26;
          fill:#cd5a26;
        }
      }
    }
  }
}

.main-menu, .sub-menu {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;

  li {
    //font-size: 1rem;
  }
}

.main-menu {
  & > li {
    //display: inline-block;
    //position: relative;
    //vertical-align: middle;

    a {
      //display: block;
    }

    &.active {
      .sub-menu {
        @include show-menu;
      }
    }

    &:hover {
      @media only screen and (min-width: $responsive-menu-breakpoint + 1 ) {
        .sub-menu {
          @include show-menu;
        }
      }
    }
  }

  .expand {
    //display: none;
  }

  @media only screen and (max-width: $responsive-menu-breakpoint) {
    @include hide-menu;

    width: 80%;
    left: 10%;

    li {
      display: block;
    }

    .expand {
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      padding: 0.5rem 1rem;

      z-index: 15;

      transform-origin: 50%;
      transition: 200ms all;

      &.active {
        transform: rotate(45deg);
      }
    }
  }

  &.active {
    @media only screen and (max-width: $responsive-menu-breakpoint) {
      @include show-menu;
    }
  }
}

.sub-menu {
  @include hide-menu;

  @media only screen and (max-width: $responsive-menu-breakpoint) {
    display: block;
    z-index: 20;
    width: 100%;
  }

  li {
    a {
      display: block;
    }
  }
}

// Responsive
.toggle-menu {
  display: none;

  @media only screen and (max-width: $responsive-menu-breakpoint) {
    display: inline-block;
    float:right;
  }
}