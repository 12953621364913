.article-kzp{
    margin-bottom:$grid-gutter-width;
    h1,h2 {
      font-size: 20px;
      font-style: italic;
      font-weight: 600;
      color: #1d3056;
      border-bottom: 1px solid #1d3056;
      margin: 0;
      padding: 8px 0;
      margin-bottom: 10px;
      text-transform: uppercase;
      a{
        color:#1d3056;
        &:hover{
          color:#eb5b26;
        }
      }
    }
  .article-content{
    color:#1d3056;
    font-style:italic;
    font-size:14px;
    line-height:18px;
    padding:8px 0;
    a{
      color:#eb5b26;
      &:hover{
        color: #cd5826;
      }
    }
  }

  .article-img-overflow{
    height:143px;
    width:100%;
    overflow:hidden;
    position:relative;
    margin-bottom:$grid-gutter-width/2;
    color:#5d5d5d;
    img{
      vertical-align:middle;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      min-width:100%;
    }
    .article-img-label{
      @include skewx();
      background:#eb5b26;
      font-size:12px;
      color:#fff;
      position:absolute;
      right:12px;
      top:0;
      padding:5px 6px;
      line-height:12px;
    }
  }


  ul{
    padding-left: 20px;
    li{
      margin-bottom: 12px;
    }
  }
  &.full-article{
    .article-img-overflow{
      height:243px;
    }
  }
}