.kzp-pager {
  padding-left: 0;
  margin:$grid-gutter-width auto $grid-gutter-width auto;
  list-style: none;
  text-align: center;
  display: inline-block;
  @include clearfix;
  li {

    display:inline-block;
    float:left;
    >a, >span {

      @include skewx();
      display:inline-block;
      padding: 10px 12px;
      min-width:170px;
      line-height: 1.42857;
      white-space: nowrap;
      font-size: 14px;
      color:#fff;
      background:#7c7c7c;
      font-weight: 400;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      text-transform:uppercase;
      transition:all .3s ease;
      border:1px solid #eb5b26;
      cursor:pointer;


      &:hover,
      &.active{
          background:#eb5b26;

      }
      &.active{
          cursor:default;
      }
    }
    &.active{
      >a, >span {
        background:#eb5b26;
        cursor:default;
      }
    }
  }
}

//

.kzp-pagination {
  padding-left: 0;
  margin:$grid-gutter-width auto $grid-gutter-width auto;
  list-style: none;
  text-align: center;
  display: inline-block;
  @include clearfix;
  li {

    display:inline-block;
    float:left;
    >a, >span {

      @include skewx();
      display:inline-block;
      padding: 9px 12px;
      min-width: 43px;
      //min-width:170px;
      line-height: 1.42857;
      white-space: nowrap;
      font-size: 14px;
      color:#fff;
      background:#7c7c7c;
      font-weight: 400;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      text-transform:uppercase;
      transition:all .3s ease;
      border:1px solid #eb5b26;
      cursor:pointer;


      &:hover,
      &.active{
        background:#eb5b26;

      }
      &.active{
        cursor:default;
      }
    }

    &.active{
      >a, >span {
        background:#eb5b26;
        cursor:default;
      }
    }
  }
}


//

/****************************************
  IMPORTANT
*****************************************/
.scrollTop {
  @include skewx();
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: #bfbfbf;
  padding: 5px 10px 7px;
  font-size: 22px;
  color: #fff;
  opacity: 0;
  transition: all .4s ease-in-out 0s;
  line-height: 1;
  cursor:pointer;
  &:hover{
    background-color: #eb5b26;
  }
}